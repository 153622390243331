/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
/* You can add global styles to this file, and also import other style files */


/* fonts size */

.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}


/* font weight */

.font-100 {
    font-weight: 100 !important;
}

.font-200 {
    font-weight: 200 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-800 {
    font-weight: 800 !important;
}


/* spacing  */

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 2rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 2rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pl-4 {
    padding-left: 1.25rem !important;
}

.pl-5 {
    padding-left: 2rem !important;
}

.color-295cb7{
    color: #295cb7 !important;
}

.cursor{
    cursor: pointer !important;
}

/* chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
  }


/* body color */

body {
    background-color: #f5fbff ;
    font-family: "Open Sans", sans-serif !important;
}

svg {
    fill: #49495a;
    cursor: pointer;
}

.fa {
    cursor: pointer;
}

.form-check .form-check-input {
    border: 1px solid #49495a !important;
}


.form-check-input:focus {
    box-shadow: none !important;
}

/* buttons start */
.btn-danger.danger-btn{
    background-color: #e00000 !important;
    border-color: #e00000 !important;
}
.btn-danger:hover , .btn-info:hover,
.btn-success:hover , .btn-primary:hover {
    box-shadow:0 .5rem 1rem #00000026!important;
}

.save-btn.btn-success:hover , .btn-success:disabled ,
.save-btn.btn-success:focus 
{
    background: #00bd1e !important;
    border-color: #00bd1e !important;
}

.btn-secondary:hover {
    box-shadow:0 .5rem 1rem #00000026!important;
}
nav .btn-secondary:hover{
    background-color: transparent;
}

.btn-success.pre-auth-btn {
    font-family: "Archivo Black", sans-serif;
    font-size: 22px !important;
    border-radius: 10px !important;
    background-color: #00b200 !important;
    border: #00b200 !important;
}

.btn-success.pre-auth-btn2 {
    font-family: "Archivo Black", sans-serif;
    font-size: 22px !important;
    border-radius: 10px !important;
    background-color: #00b200 !important;
    border: #00b200 !important;
    padding: 7px 86px 7px 86px !important;
}
.btn-success.pre-auth-btn2:hover {
    box-shadow:0 .5rem 1rem #00000026!important;
}
.login-card.login-bg .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px) !important;
    padding: 30px 15px !important;
}
.admin-patient-list-scroller .btn-secondary:hover {
    background: #fefefe !important;
    color: #49495b!important;
    border: 1px solid #e6e6e6 !important;
    box-shadow: none!important;
}

.btn-success.save-btn  {
    font-family: 'Archivo Black', sans-serif;
    font-size: 15px;
    border-radius: 7px;
    background-color: #00BD1E;
    border-color: #00BD1E;
    color: #FFFFFF;
}
/* buttons end */
.fa-sort-desc {
    color: #cbd1e1 !important;
    pointer-events: none;
    cursor: pointer !important;
}
h2 .breadcrumb{
    transition: 0.3s;
}
h2 .breadcrumb:hover{
    opacity: 0.8;
}




/* authentication screens start */

.authentication-labels label {
     
        display: inline;
        font-weight: 700;
        color: #49495b;
        font-size: 18px;
  
}



.authentication-cards {
    margin-top: 6rem !important;
}

h2.heading-text {
    font-weight: 800 !important;
    font-size: 30px !important;
    letter-spacing: -1px;
    color: #49495b !important;
}

h2 {
    font-weight: 800 !important;
    font-size: 30px !important;
    letter-spacing: -1px;
    color: #49495b !important;
}

.form-control:focus {
    color: #212529 !important;
    background-color: none !important;
    border-color: #c4c4c4 !important;
    box-shadow: none !important;
}

.btn:focus {
    box-shadow: none !important;
}

/* login screen start */

.login-screen .register-card.card {
    margin-bottom: 45px !important;
}

.login-screen .form-check-input {
    height: 1.3em !important;
    width: 1.3em !important;
}

.login-screen .register-btn {
    font-family: "Archivo Black", sans-serif;
    font-size: 22px;
    background: #2e5fbd;
    border-radius: 10px;
    border: #2e5fbd !important;
    letter-spacing: -1px;
}


/* logout modal  */

.login-screen .logout-btn {
    font-family: "Archivo Black", sans-serif;
    font-size: 22px;
    background: #2e5fbd;
    border-radius: 10px;
    border: #2e5fbd !important;
    padding: 7px 115px 7px 115px;
}

.login-screen .modal-title-notification {
    font-size: 49px !important;
    font-weight: 800;
    line-height: 65px;
    letter-spacing: -0.065em;
    color: #49495b;
}

.login-screen .modal-notification {
    font-weight: 600;
    padding: 3px 0px 3px 0px;
    border-bottom: none;
    padding: 20px;
    font-size: 18px;
    line-height: 89.18%;
    letter-spacing: -0.065em;
    border-radius: 16px;
    color: #49495b !important;
}

.login-screen .fa-times-circle-o {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    font-size: x-large;
}

.login-screen .modal-content-bg {
    background-color: #c3c3c3 !important;
    border-radius: 16px;
}

.login-screen .disclaimer-bg{
    border-radius: 10px;
    color: #ff3324;
    border: #c4c4c4 !important;
}
.login-screen .disclaimer-text{
    font-size: 18px;
    letter-spacing: -1px;
    color: #fff !important;
}
.login-screen .link-color{
    color: #00FFFF !important;
    font-size: 18px;
}

.login-screen .fa-exclamation-triangle{
    position: relative;
    right: 8px;
    font-size: 30px;
}
.login-screen .fa-exclamation-triangle{
    position: relative;
    right: 8px;
    font-size: 30px;
}


/* login screen end */


/* signup screen start */

.register-checkbox .form-check-input {
    height: 1.3em !important;
    width: 1.3em !important;
    border: 1px solid;
}


/* signup screen end */


/* complete registration screen start */

.complete-registration .label-text {
    color: #4e4e60;
}

.complete-registration .form-group {
    margin-bottom: 0.1rem !important;
}

.complete-registration option {
    font-size: 18px;
}

.complete-registration .form-check-input {
    margin-top: 0.5rem !important;
    transform: scale(1.5) !important;
}

.complete-registration .month-icon,
.complete-registration .day-icon,
.complete-registration .year-icon {
    position: absolute;
    right: 27px;
    top: 6px;
}


.complete-registration .state {
    position: absolute;
    right: 27px;
    top : 5px;
}

@media (max-width: 768px) { 
    .complete-registration .state ,
    .complete-registration .month-icon,
    .complete-registration .day-icon,
    .complete-registration .year-icon{
        right: 20px;
    }
 }

.fa-sort-desc {
    pointer-events: none;
}

.complete-registration .input-field {
    position: relative;
}

.complete-registration .select-option.form-control-lg {
    min-height: calc(3.3rem + 2px) !important;
    padding: 0.5rem 1rem;
}


/* complete registration screen ends */


/* confirmation message screen start */

.confirmation-message h2.heading-text {
    line-height: 3rem !important;
    color: #49495b !important;
}


/* confirmation message screen end */


/* complete registration 2nd  + A HouseHold Member + Edit A Household Member screens start  */


.family-member-modal .modal-content {
    background-color: #e1e1e1 !important;
    border: none;
    border-radius: 16px;
}
.family-member-modal .modal-dialog .btn-primary {
        color: #fff;
        font-weight: 700;
        background-color: #00b200;
        border-color: #00b200;
        border-radius: 10px;
}
.family-member-modal .modal-dialog .btn-primary:hover {
    color: #fff !important;
    background-color: #00b200 !important;
    border-color: #00b200 !important;
}
.family-member-modal .modal-dialog .btn-primary:focus {
    color: #fff !important;
    background-color: #00b200 !important;
    border-color: #00b200 !important;
}



/* .registration-two p, */
.registration-two span u {
    margin-left: 5px !important;
}

 @media (max-width: 992px) {
    .registration-two .profile-picture-size{
        height: auto !important;
        width: 100% !important;
    }
    
}

.registration-two p{
    color: #49495b!important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;

}

.add-family-member .upload-profile span {
    color: #49495a !important;
}
/* .registration-two span */
.registration-two .main,
.add-family-member .main,
.edit-household-member .main {
    background-color: #c4c4c4;
    border-radius: 20px;
}

.edit-household-member .form-check-label , 
.registration-two .form-check-label {color: #49495b;}

.registration-two .desc-icon,
.add-family-member .desc-icon {
    position: absolute;
    right: 24px;
    top : 0;
}

.registration-two .height-text .danger-text,
.edit-household-member .height-text .danger-text,
.add-family-member .height-text .danger-text {
    color: #dc3545 !important;
}

.registration-two .state,
.edit-household-member .state {
    position: absolute;
    right: 10px;
    top : 0px
}

.registration-two textarea,
.add-family-member textarea,
.edit-household-member textarea {
    resize: none;
    height: 90px;
}

.registration-two .radio-btn,
.add-family-member .radio-btn,
.edit-household-member .radio-btn {
    margin-right: 7px;
    border: 1px solid;
}

.validation-sm-font{
    font-size: 1rem !important;
}
.registration-two .profile-img,
.edit-household-member .profile-img {
    border-radius: 40px;
    height: 250px;
    width: 250px;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .edit-household-member .profile-img {
        border-radius: 40px;
        height: 100%;
        width: 100%;
    }
}

.registration-two .weight,
.add-family-member .weight,
.edit-household-member .weight {
    margin-top: -22px;
}

.registration-two .form-2 {
    padding-right: 25px;
}

.registration-two .update-img,
.add-family-member .update-img,
.edit-household-member .update-img {
    opacity: 0;
}

.cursor-point {
    cursor: pointer !important;
}
.registration-two .upload-picture-text{
    color: #49495B !important;
}


.edit-household-member .desc-icon {
    position: absolute;
    right: 8px;
    bottom: 10px;
}

.registration-two select.form-control,
.complete-registration select.form-control,
.add-family-member select.form-control,
.edit-household-member select.form-control {
    font-size: 18px !important;
}

.registration-two .select-option.form-control-lg {
    min-height: calc(1rem + 3rem + 2px) !important;
    padding: 0.5rem 1rem;
}

.registration-two .form-control,
.add-family-member .form-control,
.edit-household-member .form-control , 
.patient-consult-report .form-control {
    border-radius: 10px;
}



.add-family-member span u,
.edit-household-member  .update-profile span {
    color: #49495b;
}
.edit-household-member span {
    color: #fff;
}

.patient-dashboard .modal-btn {
    background-color: #2e5fbd !important;
    border-color: #2e5fbd !important;
}

.add-family-member p,
.edit-household-member p {
    color: #49495b !important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;

}

 @media (max-width: 992px) {
    .add-family-member .profile-picture-border{
        height: auto !important;
        width: 100% !important;
    }
    
}


@media (max-width: 1200px) {
    .registration-two .profile-pic {
        margin-left: 1rem !important;
    }
}

@media (max-width: 991px) {
    .profile-img {
        height: auto !important;
    }
}
.add-family-member .profile-picture-border {
    border-radius: 40px;
}
.add-family-member .paragrapgh-margin p , .edit-household-member p{
    margin-left: 0.25rem !important;
}
.edit-household-member .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #00bd1e!important;
    background: #00bd1e!important;
}
.edit-household-member .multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #00bd1e!important;
    border: 2px solid #00bd1e!important;    
}

.edit-household-member .multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #00bd1e!important;  
}
/* complete registration 2nd screen end */


/* authentication screens ends */


/* Patients Dashboard Screens Start*/

.patient-dashboard {
    color: #49495b;
}

.patient-dashboard .green-card {
    background-color: #0eb200;
}

.patient-dashboard .card-bg-color {
    background-color: #c4c4c4;
}

.patient-dashboard .patient-cards-radius {
    border-radius: 20px !important;
}

.patient-dashboard .border-none {
    border: none;
}

.patient-dashboard h2 {
    color: #49495b;
}

.patient-dashboard .house-hold {
    width: 100%;
    border-radius: 24px;
    background: transparent;
    object-fit: cover;
}



.patient-dashboard .consult .card .card-body {
    cursor: pointer;
}

.patient-dashboard .update-profile-text span {
    cursor: pointer;
}

.patient-dashboard .status {
    position: relative;
    top: -18px;
    border-radius: 10px;
    background-color: #ffffff;
}

.patient-dashboard .patient-profile-pic {
    border-radius: 30px;
    width: 100%;
}

.patient-dashboard .update-address {
    line-height: 1;
    letter-spacing: -1px;
}

.patient-dashboard .family-household-members {
    max-height: calc(86vh - 400px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding: 0px 15px 0px 0px;
}

.patient-dashboard .scroller::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: transparent;
    /* or add it to the track */
}


/* // Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .patient-dashboard .house-hold {
        width: 100%;
        height: auto !important;
        object-fit: cover;
        border-radius: 40px;
    }
}


/* Add a thumb */

.patient-dashboard .scroller::-webkit-scrollbar-thumb {
    background: #aaa;
}

@media (max-width: 991.98px) {
    .patient-dashboard .family-card-height {
        height: auto;
    }
}


/* Patients Dashboard Screens end*/


/* Consult screen start*/

.choose-consult-type {
    color: #49495a;
    margin-top: 8rem;
}

.choose-consult-type .card-body h2 {
    left: 30px;
    font-size: 30px !important;
}

.choose-consult-type .card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #c4c4c4 !important;
    background-clip: border-box;
    border: 1px solid rgba(196, 196, 196, 255);
    border-radius: 1.25rem !important;
    height: 215px !important;
    cursor: pointer;
}

.choose-consult-type .card-title {
    line-height: 0.9;
}

.choose-consult-type .card .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.choose-consult-type .card:hover {
    border-color: #49495b !important;
    transform: scale(1.1) !important;
}
.choose-consult-type .card {
    cursor: pointer;
    transition: .3s ease-in;
}
@media (max-width: 768px) {
    .choose-consult-type .card {
        width: auto !important;
    }
    .choose-consult-type .col-md-6.mb-4 {
        display: inline-block !important;
    }
}


/* Consult screen start*/


/* Patient screens height css start */

.patient-screens-height {
    margin-top: 6rem;
}


/* Patient screens height css end  */


/* patient-intake-screens start */

.patient-intake {
    color: #49495b;
}

.patient-intake .card {
    border: none !important;
}
.patient-intake .card.questions-list {
    background-color: transparent !important;
}

.patient-intake .phone-call-cards .card-title {
    position: relative;
    height: 80px !important;
    font-size: 22px !important;
    letter-spacing: -1px;
    text-align: center !important;

}

.patient-intake .phone-call-cards p {
    position: relative;
    font-size: 14px !important;
    overflow: auto;
    height: 280px !important;
}

.patient-intake .phone-call-cards .card {
    border: 1px solid #cbd1e1 !important;
    height: 515px;
    border-radius: 17px;
    background-color: #c4c4c4;
}

.patient-intake .btn-success.pre-auth-btn {
    color: #fff;
    background-color: #00be32 !important;
    border: #00be32 !important;
}

.patient-intake .btn-success.pre-auth-btn:hover {
    box-shadow: 0 .5rem 1rem #00000026!important;

}

.patient-intake .phonecall-btn {
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 7px;
    background-color: #00be32 !important;
    border: #00be32 !important;
}

.patient-intake .carousel-indicators .carousel-control-prev {
    box-sizing: border-box !important;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.patient-intake .carousel-indicators .carousel-control-next {
    box-sizing: border-box !important;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.patient-intake .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 21px !important;
    height: 20px !important;
    padding: 0;
    border-radius: 99% !important;
    margin-right: 6px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgb(32, 28, 28);
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.patient-intake .carousel-indicators .active {
    opacity: 3;
}


.patient-intake .billing-ques h6 {
    letter-spacing: -1px;
}


/* BILLING CARD */

.patient-intake .billing-card .billing-price {
    justify-content: center;
    display: flex;
    position: relative;
    top: 30px;
}

.patient-intake .billing-card .card-title {
    font-size: 30px !important;
    justify-content: center;
    display: flex;
    position: relative;
    top: 20px;
}

.patient-intake .billing-card {
    border: 1px solid #cbd1e1 !important;
    height: 680px;
    border-radius: 17px;
    background-color: #c4c4c4;
}

@media (max-width: 992px) {
    .patient-intake .billing-card {
        border: 1px solid #cbd1e1 !important;
        height: 734px;
        border-radius: 12px;
        background-color: #c4c4c4;
    }
}

.patient-intake .business-phonecall-card {
    border: 1px solid #cbd1e1 !important;
    border-radius: 17px;
    background-color: #c4c4c4;
}

.patient-intake .form-control-sm {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.1rem !important;
    line-height: 1.5;
    background: #ffffff;
    border-radius: 8px !important;
}

.patient-intake .list-group-color {
    color: #4e4e4e;
}

.patient-intake .billing-price-color {
    font-family: "Archivo Black", sans-serif;
    font-size: 55px !important;
    letter-spacing: -2px;
    color: #49495a !important;
}

.patient-intake .doller-sign {
    top: 0.5em;
}

.patient-intake .State {
    position: absolute;
    right: 26px;
    top: 22px;
}

.patient-intake .form-group {
    margin-bottom: 0.1rem !important;
}

.patient-intake .billing-text {
    letter-spacing: -1px;
    color: #49495b;
}

.patient-intake .fa-caret-left.active:before {
    color: #000 !important;
    opacity: 3;
}

@media (min-width: 1200px) {
    .patient-intake .col-xl-6 {
        width: 63% !important;
    }
}

@media (min-width: 991.98px) {
    .patient-intake .col-lg-6 {
        width: 63% !important;
    }
}

@media (min-width: 991.98px) {
    .patient-intake .col-lg-6 {
        width: 63% !important;
    }
}


/* patient consult selection */

.patient-intake .consult-type {
    font-size: 31px !important;
    color: #49495b;
    letter-spacing: -1px;
    font-weight: 800;
}

.patient-intake .talk-btn {
    background-color: #ffc029 !important;
    border-radius: 10px;
    border: #ffc029 !important;
    color: #49495b !important;
    width: 100% !important;
}

.patient-intake .btn-light:hover {
    color: black!important;
    opacity: .8;
    box-shadow: 0 .5rem 1rem #00000026!important;
}

.patient-intake .time-lables span {
    background: #ffc029;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    color: #49495b;
    font-size: 14px;
    font-weight: 800;
    padding: 4px;
    margin-bottom: 4px;
    cursor: pointer;
}

.patient-intake .fa-caret-down {
    position: absolute;
    right: 1.2rem;
    bottom: 1.2rem;
    cursor: pointer;
}

.patient-intake .fa-caret-up {
    position: absolute;
    right: 1.2rem;
    top: 4px;
    cursor: pointer;
}

.patient-intake .paragraph-text {
    letter-spacing: -1px;
}

.state-Select-option{
    min-height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 8px 16px !important;
    font-size: 1.1rem !important;
    border-radius: 8px !important;
}


/* patient-intake-screens end */


/* Admin dashboard screen css start */
.admin-dashboard .btn:hover {
    color: #fff !important;
}
.admin-dashboard .btn-secondary.patient{
    background-color: #5f27cd;
    border-radius: 7px;
    color: #ffffff;
    font-weight: 800;
    size: 23px;
    align-items: center;
    padding: 12px 33px;
}



.admin-dashboard .btn-secondary:hover  {
    background-color: #5f27cd !important;
}


.admin-dashboard .bar-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-dashboard .bar-text span {
    text-align: center;
    line-height: 1.2;
    font-size: small;
    color: #b5b6b6;
    font-weight: 500;
}


/* bar chart*/

.admin-dashboard .bar-chart {
    border-radius: 20px;
    position: relative;
    border: none !important;
    box-shadow: 0 11px 13px 0 rgb(0 0 0 / 24%);
    transition: 0.3s;
    background-color: #f8f8f8 !important;
}

.admin-dashboard .bar-chart .header-dropdown {
    position: absolute;
    top: 16px;
    right: 25px;
}

.admin-dashboard .bar-chart .dropdown .btn-secondary {
    color: #ffcc50 !important;
    background-color: #ffdc863b !important;
    border: 1px solid #e6e6e6 !important;
    display: flex;
    gap: 3px;
    border-radius: 9px;
    color: #ffdc86;
    font-weight: bold;
    height: 28px;
    color: #ffdc86;
    font-size: 13px !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 38%) !important;
    transition: 0.3s !important;
}

.admin-dashboard .admin-dashboard .bar-chart .dropdown-toggle::after {
    color: #ffc107 !important;
    border-top: 0.6em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    border-left: 0.5em solid transparent !important;
    margin-top: 5px !important;
}

.admin-dashboard .loader-size {
    position: absolute !important;
    width: 1100px !important;
    bottom: -586px !important;
}


@media (max-width: 576px)
 {
    .admin-dashboard .view-patient-btn , .admin-dashboard .heading  {
        justify-content: center!important;
    }
 }


/* Admin dashboard screen css end */


/* Admin patient list screen css start */

.admin-patient-list h2 {
    display: inline-block !important;
}

.admin-patient-list .btn-secondary, .admin-settings-provider .btn-secondary {
box-shadow: none !important;

}

.admin-patient-list table th {
    background-color: #ffffff !important;
    color: #49495b !important;
}

.admin-patient-list .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #e1e1e1 !important;
}

.admin-patient-list .search-svg,
.calander-svg svg {
    fill: #aeb1b3 !important;
}

.admin-patient-list .search-svg {
    position: absolute;
    top: 5px;
    right: 20px;
}

.admin-patient-list .search-svg #Capa_8 {
    fill: #aeb1b3 !important;
}

.admin-patient-list .calander-svg {
    position: absolute;
    top: 6px;
    right: 20px;
}

.admin-patient-list .form-control {
    background-color: #ffffff00 !important;
    border: 1px solid #ced4da !important;
    border-radius: 7px;
}

.admin-patient-list .filter .fa {
    font-size: larger !important;
}

.admin-patient-list .filter {
    letter-spacing: -1px;
    color: #49495b;
}
.btn:disabled{
    pointer-events: none;
    opacity: 0.65;
}
.admin-patient-list .primary-btn {
    width: 100%;
    color: #fff !important;
    background-color: #38a9e9 !important;
    border-radius: 8px;
    border: 1px solid transparent !important;
    transition:  all 0.5s ease-out;
}
.admin-patient-list .primary-btn:hover {
    color: #38a9e9;
    background-color: #fff !important;
    border-radius: 8px;
    border: 1px solid #38a9e9 !important;
}

.admin-patient-list .primary-btn:active {
    border: 1px solid #38a9e9 !important;
}

.admin-patient-list .primary-btn:focus {
    border: 1px solid #38a9e9 !important;
}

.admin-patient-list .fa {
    cursor: pointer;
}

.admin-patient-list .intake-card {
    background-color: #e1e1e1 !important;
}

.admin-patient-list .intake-icon {
    position: absolute;
    right: 7px;
    top: 5px;
}

.admin-patient-list .left-border {
    border-right: 2px solid #d6d9dc;
     height: calc(100vh - 80px) !important;
}

@media (max-width: 992px) {
    .admin-patient-list .left-border {
        border-right: none !important;
     height: auto !important;

    }
}

.admin-patient-list .btn-primary:focus {
    background-color: #38a9e9 !important; 
}
.admin-patient-list .intake-card {
    color: #49495b;
}

svg {
    cursor: pointer;
}

.admin-patient-list #comm-17_chat {
    fill: #ff8918;
}

.admin-patient-list #phone {
    fill: #00bd1e;
}

.admin-patient-list #search_folder {
    fill: #38a9e9;
}

.admin-patient-list .icons {
    margin-top: -14px;
}

 .btn-info {
    background: #00bd1e !important;
    border-radius: 8px !important;
    color: #fff !important;
    border: none !important;
    font-weight: 800 !important;
    width: 100%
 }

 .admin-patient-list .btn-danger {
    border-radius: 8px !important;
    border: none !important;
    font-weight: 800 !important;
    width: 100%
 }
 .admin-patient-list textarea , .admin-settings-patient textarea{resize: none;}

.admin-patient-list .primary-btn {
    width: 100%;
    color: #fff;
    background-color: #38a9e9;
    border-radius: 8px;
    border: none;
}


/* timeline */




/* End timeline */

.admin-patient-list .text-size {
    font-size: 13px;
    color: #49495b;
    letter-spacing: -1px;
}

.admin-patient-list .table .dropdown .btn-secondary {
    color: #49495b !important;
    font-size: 14px !important;
}

.admin-patient-list .dropdown-form {
    display: flex;
    width: 100%;
}

.admin-patient-list .header-dropdown {
    display: flex;
    width: 100%;
}

.admin-patient-list .patient-list-text {
    color: #49495b !important;
} 

.admin-patient-list .dropdown-form .btn-secondary {
    width: 100%;
    margin-right: 0;
    display: block;
    display: flex;
    justify-content: space-between;
    color: #49495b !important;
    background-color: transparent !important;
    border: 1px solid #e6e6e6 !important;
    font-size: 14px;
    border-color: #ced4da !important ;
    border-radius: 7px;
}

.admin-patient-list .dropdown-form .btn-secondary:focus{
    border: 1px solid #ced4da !important;
    background-color: transparent !important;

}

.admin-patient-list .tbl-dropdown {
    margin-top: -15px;
}

@media (max-width: 1200px) {
    .admin-patient-list .dropdown-form .btn-secondary {
        font-size: 12px;
    }
}

.admin-patient-list .communication-text .dropdown-toggle:after {
    margin-left: -1.75rem !important;
}

.admin-patient-list .modal-content {
    background-color: #c4c4c4 !important;
}

.modal-dialog svg {
    fill: #ffbf17 !important;
}

.admin-patient-list .modal-dialog .fa-sort-desc {
    color: #ccd2e3 !important;
}

.admin-patient-list .message-modal .btn-success {
    border-radius: 10px;
}

.admin-patient-list .rx-view .modal-header span {
    position: absolute;
    right: 5px;
    top: -20px;
}

.admin-patient-list .choose-provider-icon {
    position: absolute;
    right: 5px;
    top: 15px;
    z-index: 1111 !important;
}

.admin-patient-list .message-modal .form-control {
    border-radius: 10px !important;
}

.admin-patient-list .modal-content.content-popop {
    background-color: #e1e1e1 !important;
    border: none;
    border-radius: 16px;
}

.admin-patient-list .modal-doctor {
    margin: 5px 0px 11px !important;
}

.admin-patient-list .doctor-bg {
    background-color: #ffffff;
    padding-left: 30px;
}

.admin-patient-list .provider-radio-btn {
    margin-top: 8px;
    margin-left: 0px;
    height: 20px;
    width: 20px;
}

.admin-patient-list .provider-text-color {
    color: red;
}

.admin-patient-list .doctor-disable {
    color: #a59696;
}

.admin-patient-list .vacation-disable {
    color: #ff8080;
}


/* Cancel/Refund */

.admin-patient-list .cancel-refund {
    font-size: 49px !important;
    line-height: 41px !important;
}

.admin-patient-list .enter-reason {
    border: 1px solid #a59696 !important;
    border-radius: 10px !important;
    padding: 6px 110px 10px 15px !important;
    
}

.choose-provider {
    font-weight: 800 !important;
    font-size: 49px !important;
    letter-spacing: -2px;
    line-height: 41px !important;
    color: #49495b !important;
}
.admin-patient-list .patient-notes-color{
    color: #49495b !important;
}


/* RX modal */

.admin-patient-list .file-description {
    font-weight: 600;
    font-size: 14px;
    line-height: 84.68%;
    letter-spacing: -1px !important;
    color: #49495b;
}


/* Pdf viewer modal  */

.admin-patient-list .question-notes {
    background-color: #6a6a6a;
}

.admin-patient-list .page {
    padding: 4.5rem 5rem;
    height: calc(100vh - 150px);
    margin-top: 0rem;
    overflow: auto;
}

.admin-patient-list .card-bg-color {
    background: #fff !important;
}

.admin-patient-list .download-btn {
    color: #ffbf17;
}

.admin-patient-list .cross-icon-one {
    top: 24px !important;
    right: 10px !important;
}

.admin-patient-list .icons-rotate {
    transform: rotate(-90deg) !important;
}

.admin-patient-list .choose-provider {
    font-weight: 800 !important;
    font-size: 49px !important;
    letter-spacing: -2px;
    line-height: 41px !important;
    color: #49495b !important;
}


/* collaps cards */

.admin-patient-list .collapse .card {
    background-color: #e1e1e1 !important;
    border: none !important;
}

.admin-patient-list .download-rx {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1 !important;
}

@media (max-width: 1360px) {
    .admin-patient-list .custom {
        width: 50% !important;
    }
}

@media (max-width: 768px) {
    .admin-patient-list .custom {
        width: 100% !important;
    }
}


/* Admin patient list screen css end  */


/* Admin settings screen CSS start */

.admin-settings .text-center {
    position: relative;
    left: 47px;
}

.admin-settings .bg-c4c4c4 {
    background-color: #c4c4c4;
    border-radius: 12px;
}
.admin-settings .font-weight-bold {
    padding: 3.5rem !important;
    position: relative !important;
    width: 262 !important;
    height: 262 !important;
}
.admin-settings .rounded-cards {
    cursor: pointer;
    transition: .3s ease-in;
}
.admin-settings .rounded-cards:hover {
    border-color: #49495b !important;
    transform: scale(1.1);
    
}



/* Admin settings screens CSS ends */


/* Admin settings Report css start */

.admin-settings-report ul li:nth-child(odd) {
    background: #e1e1e1;
}

.admin-settings-report li {
    padding: 20px;
}

@media (max-width: 992px) {
    .admin-settings-report .main {
        padding: 0px !important;
    }
}

.admin-settings-report .report-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 105.68%;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #49495b;
}

.admin-settings-report .bg-color {
    background-color: #e5e5e5;
}

.admin-settings-report .btn-secondary {
    font-family: "Open Sans";
    font-style: normal;
    padding: 8px;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    border: none;
    letter-spacing: -0.065em;
    background: #00bd1e;
    border-radius: 7px;
}
.admin-settings-report .btn-secondary:hover{
    background: #00bd1e !important;
    color: #fff!important;

}

.admin-settings-report svg {
    fill: #fff;
    cursor: pointer;
    pointer-events: none;
}

.admin-settings-report h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: 84.68%;
    display: flex;
    align-items: center;
    letter-spacing: -0.065em;
    color: #49495b;
}

.admin-settings-report h5 {
    color: #49495b;
    font-weight: 900;
    line-height: 15px;
    font-size: 18px;
    letter-spacing: -1px;
}

.admin-settings-report p {
    margin-left: 5px;
    font-size: 14px;
    color: #49495b;
    font-weight: 700;
    line-height: 13px;
}

.admin-settings-report .radio-btn {
    border: 1px solid;
}

.admin-settings-report .radio-text {
    color: #49495b;
    font-weight: 700;
    font-size: 14px;
}

.admin-settings-report .card-body {
    height: 100px;
    overflow: auto !important;
}

.admin-settings-report .card {
    border-radius: 5px;
}

.admin-settings-report .card-lables {
    background-color: #00bd1e !important;
    border-radius: 6px;
    font-size: 11px;
}

.admin-settings-report .input-group-text {
    background-color: transparent;
    border: none;
}
.admin-settings-report .date-range-svg {
    position: absolute;
    top: 25px;
    right: 7px;
    pointer-events: none;
}

.admin-settings-report .date-range-svg svg {
    fill: rgba(132, 139, 160, 0.25) !important;
}


.admin-settings-report .report-daterange input , .admin-settings-clients input{
    border: none !important;
}


/* Admin settings Report css end */


/* Admin settings Pharmacy CSS start */
.admin-settings-pharmacy .admin-settings-cards , .admin-settings-admin .admin-settings-cards,
.admin-settings-medication .admin-settings-cards , .admin-settings-clients .admin-settings-cards , 
.admin-settings-report .admin-settings-cards {
    background-color:#C4C4C4BF ;
    border-radius: 17px;
}
.admin-settings-admin .pr-35{
    padding-right: 40px;
}
.admin-settings-pharmacy .table-text {margin-top: -22px;}
.admin-settings-pharmacy .label-text  { font-size: 16px; color: #49495B; }
.admin-settings-clients .table>:not(caption)>*>* {
    color: #49495B ;
} 
 .admin-settings-clients .dropdown-toggle:after {
     position: absolute;
     right: 16px;
 }

 .admin-settings-clients .dropdown .btn-secondary:hover {
     box-shadow: none !important ;
     background-color: #fff !important;
     color: #49495b !important;
     border: 1px solid #fff !important;
 }
.admin-settings-clients .plus-icon{
    position: absolute;
    right: 7px;
    top: 7px;
}
.admin-settings-pharmacy input , .admin-settings-clients input ,
.admin-settings-medication input , .admin-settings-admin input ,
.admin-settings-clients .state-option {border-radius: 10px;}

.admin-settings-provider .toggle-text {
    color: #49495B !important;
}

.admin-settings-pharmacy .fa-search {
    position: absolute;
    right: 26px;
    top: 10px;
}

.admin-settings-pharmacy .search-icon .fa-solid {
    color: #ccd2e3;
}

.admin-settings-pharmacy #Capa_1 {
            fill: #49495B !important;
        }
    
@media (min-width: 1200px) { 
    .admin-settings-pharmacy .left-border { border-left: 2px solid #d6d9dc; height: calc(100vh - 80px); }
    .admin-settings-pharmacy .table-row {padding-right: 1rem;}
    .admin-settings-pharmacy .right-card {padding-left: 2rem;}
        }

.admin-settings-pharmacy .input-field , .admin-settings-medication .input-field ,
.admin-settings-provider .input-field {
    background-color: transparent;
    border-radius: 10px !important;
}

.admin-settings-pharmacy .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: #ffffff !important;
    color: #49495b !important;

}

.admin-settings-pharmacy table {
    background-color: #e1e1e1 !important;
}

.admin-settings-pharmacy table th {
    color: #49495b;
}


.admin-settings-pharmacy .pharmacy-details {
    background-color: rgba(208, 209, 210, 0.8) !important;
}

.admin-settings-pharmacy .btn-success
{
    font-size: 15px;
    border-radius: 7px;
    background-color: #00bd1e;
    border-color: #00bd1e;
    color: #ffffff;
}

svg {
    cursor: pointer !important;
}
.admin-settings-pharmacy .fa,
svg {
    cursor: pointer !important;
}
.admin-settings-pharmacy .form-control:focus, .admin-settings-patient .form-control:focus {
    background-color: #fff;
    box-shadow: none !important;
}

.settings-search-field:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}


.admin-settings-pharmacy .State {
    position: absolute;
    right: 23px;
    top: 7px;
}

.admin-settings-pharmacy #inputState12 {
    border-radius: 10px !important;
}

.admin-settings-pharmacy .client-card .card-body {
    overflow: auto;
} 
.admin-settings-pharmacy .client-card .card {border-radius: 5px;}
.admin-settings-pharmacy .greater-icon , .admin-settings-clients .greater-icon ,
.admin-settings-report .greater-icon , .admin-settings-provider .greater-icon  {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 63px;
    font-weight: 900;
}

.admin-settings-pharmacy .card-lables {
    background-color: #38dee9;
    font-size: 16px;
    font-weight: 900;
}

.admin-settings-pharmacy .arrows,
.fa-sort-desc {
    color: #ccd2e3;
}

.admin-settings-pharmacy .fa-caret-down {
    position: absolute;
    bottom: 2rem;
    right: -27px;
}

.admin-settings-pharmacy .arrow-up {
    position: absolute;
    right: -27px;
    top: -14px;
 }
 .admin-settings-pharmacy .space , .admin-settings-clients .space {margin-top: -16px;}
 .admin-settings-pharmacy .contact-details{margin-top: -10px;}
 .admin-settings-pharmacy .ph-address {margin-top: -20px;}

@media (max-width: 991px) {
    .admin-settings-pharmacy .new-rx
     {
        padding-left: 0;
        justify-content: start !important;
    }

    .admin-settings-pharmacy .add-pharmacy-icon {
        padding-right: 4.5rem !important;
    }
}


/* Admin settings Pharmacy CSS End */


/* Admin settings Admin Screen CSS start */

.admin-settings-admin {
    color: #49495b;
}

.admin-settings-admin input {
    border-radius: 10px;
}
.admin-settings-admin .admin-ngdropdown {margin-top: -18px;}
.admin-settings-admin .fa-search {
    position: absolute;
    right: 14px;
    top: 10px;
}

.admin-settings-admin .search-icon .fa-solid {
    color: #ccd2e3;
}

@media (min-width: 1200px) {
    .admin-settings-admin .left-border {
        border-left: 2px solid #d6d9dc;
        height: calc(100vh - 80px);
    }
    .admin-settings-admin .table-row {
        padding-right: 3rem;
    }
    .admin-settings-admin .right-card {
        padding-left: 3rem;
    }
}

.admin-settings-admin .input-field {
    background-color: transparent;
    border-radius: 10px !important;
}

.admin-settings-admin .table-striped>tbody>tr:nth-of-type(even)>* ,
.admin-settings-medication .table-striped>tbody>tr:nth-of-type(even)>*{
    --bs-table-accent-bg: #ffffff !important;
}

.admin-settings-admin table {
    background-color: #e1e1e1 !important;
}

@media (max-width: 991px) {
    .admin-settings-admin .new-rx {
        padding-left: 0;
        justify-content: start !important;
    }
    .admin-settings-pharmacy .add-pharmacy-icon {
        padding-right: 4.5rem !important;
    }
}


/* Admin settings admin screen CSS end */


/* Admin settings Notification screen CSS start   */

.admin-settings-notification .pharmacy-alert {
    background-color: #ff0000;
    color: white;
    font-weight: 600;
    padding: 3px 0px 3px 0px;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
}

.admin-settings-notification .border-right {
    border-right: 1px solid #c4c4c4;
    height: calc(100vh - 110px) !important;
}




.admin-settings-notification .notification-type {
    letter-spacing: -0.065em;
    color: #494958;
    font-weight: 700;
    font-size: 18px;
    line-height: 89.18%;
}

.admin-settings-notification .notification-type-message {
    letter-spacing: -0.065em;
    color: #494958;
    resize: none;
    font-size: 18px;
}

.admin-settings-notification .past-notification-textarea {
    display: flex;
    align-items: center !important;
    background: #ffffff;
    border: 2px solid #a0a0a0;
    border-radius: 14px;
    letter-spacing: -0.065em;
    color: #494958;
    font-weight: 600;
    font-size: 18px;
    resize: none;
    min-height: 80px;
    
}

.admin-settings-notification .form-control.past-notification-textarea {
    background-color: #ffffff !important;
    font-size: 14px;
    letter-spacing: -0.065em;
}

.admin-settings-notification .past-notifications {
    margin-left: 30px;
    resize: none;
}

.admin-settings-notification .compose-message {
    padding-right: 40px;
}

.admin-settings-notification .modal-notification {
    font-weight: 600;
    padding: 3px 0px 3px 0px;
    border-bottom: none;
    padding: 20px;
    font-size: 18px;
    line-height: 89.18%;
    letter-spacing: -0.065em;
    border-radius: 16px;
    color: #49495b !important;
}

.admin-settings-notification .modal-title-notification {
    font-size: 49px !important;
    font-weight: 800;
    line-height: 65px;
    letter-spacing: -0.065em;
    color: #49495b;
}

.admin-settings-notification .fa-times-circle-o {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    font-size: x-large;
}

.admin-settings-notification .modal-content-bg {
    background-color: #c3c3c3 !important;
    border-radius: 16px;
}

.admin-settings-notification .circle-icon {
    background-color: transparent;
    border: none !important;
}

.admin-settings-notification .header-notification {
    padding: 1rem 1rem 0rem;
}

.admin-settings-notification .notification-text h2 u {
    font-size: 28px !important;
}

@media (max-width: 766.98px) {
    .admin-settings-notification .border-right {
        border-right: 0;
        height: auto !important;

    }
    .admin-settings-notification .mt-5.ml-5 {
        margin-left: 0 !important;
    }
    .admin-settings-notification .past-notifications{
        margin-left: 0;
    }
    .admin-settings-notification .compose-message{
        padding-right: 0;
    }
}

@media (max-width: 375px) {
    .admin-settings-notification .settings {
        margin-top: 4.5rem !important;
    }
}


/* Admin settings Notification screen CSS End */


/* Admin settings Medication Screen CSS Start */

.admin-settings-medication .label-text {
    font-size: 16px;
    color: #49495B;}

.admin-settings-medication textarea {
resize: none;}

.admin-settings-medication .State {
    position: absolute;
    right: 17px;
    top: 9px;
}

.admin-settings-medication input {
    border-radius: 10px;
}

.admin-settings-medication .space {
    margin-top: -16px;
}

.admin-settings-medication .fa-search {
    position: absolute;
    right: 20px;
    top: 10px;
    pointer-events: none;
    z-index: 100 !important;
}

.admin-settings-medication .search-icon .fa {
    color: #ccd2e3;
}

@media (min-width: 992px) {
    .admin-settings-medication .left-border {
        border-left: 2px solid #d6d9dc;
        margin-left: 6rem;
    }
    .admin-settings-medication .Pharmacy-card {
        margin-left: 2rem;
    }
}

@media (max-width: 992px) {
    .admin-settings-medication .data-table {
        padding-left: 0px;
        padding: 50px;
    }
}

.admin-settings-medication table {
    background-color: #e1e1e1 !important;
}

.admin-settings-medication .prescribe-note {
    border-radius: 10px;
}

.admin-settings-medication .diagnosis {
    border-radius: 10px;
}

.admin-settings-medication .second-card {
    padding-top: 21px;
}

@media (min-width: 991.98px) {
    .admin-settings-medication .medications-card {
        padding-right: 35px !important;
    }
    .admin-settings-medication .medications-heading {
        height: calc(100vh - 80px);
        border-right: 1px solid #b3b3b3 !important;
    }
}
.admin-settings-medication .pr-35 {
    padding-right: 35px;
}

@media (max-width: 991px) {
    .admin-settings-medication .new-rx {
        padding-left: 0;
        justify-content: start !important;
    }
}


/* Admin settings Client Screen CSS Start */
.admin-settings-clients .tb-text {margin-top: -7px;}
.admin-settings-clients .state {
    position: absolute;
    right: 17px;
    top: 9px;
    border-radius: 10px;}

    .admin-settings-clients .admin-settings-cards img {
        border-radius: 40px;
    
    }


    .admin-settings-clients .table-striped>tbody>tr:nth-of-type(even)>* {
        --bs-table-accent-bg: #FFFFFF
        !important;
        color: #49495B;
    }
    .dropdown-client-list-scroller::-webkit-scrollbar-track {
        background: #fff;
        width: 100%;
      }
    .admin-settings-clients table {
        background-color: #E1E1E1 !important;
    }
    .admin-settings-clients .client-border{
        height: calc(100vh - 80px) !important;
        border-right: 1px solid #B3B3B3 !important;
    }
    .admin-settings-clients .card-lables {background-color: aqua; font-size: 16px; font-weight: 900;}
    .admin-settings-clients .consult-fee {margin-top: -15px;}
    .admin-settings-clients .client-edit{
        letter-spacing: -1px;
    }
    
    .admin-settings-clients .fa-plus-circle , .admin-settings-clients .fa-minus-circle {color:  #00BD1E;}
    .admin-settings-clients.colors .form-control {
       background-color: #FFFFFF !important; 
        }
        .admin-settings-clients h6 {
            color: #49495B;
            font-size: 18px !important;
            letter-spacing: -1px ;
            font-weight: 700 !important;
        }

        .admin-settings-clients  .logo-text {
        margin-top: -7px;
        }


      @media (max-width :992px){
        .admin-settings-clients .custom-class{
               width: 100% !important;
           } 
        .admin-settings-clients .client-border {
               border-right: none !important;
               height: auto !important;
           }
           .admin-settings-clients .new-rx{
            padding-left: 0;
            justify-content: start !important;
           }
           .admin-settings-clients .admin-settings-cards img {
            border-radius: 40px;
            height: 100%;
            width: 100%;
           }

           .admin-settings-clients .client-logo .logo-text {
               display: flex !important;
               justify-content: center !important;
               margin-left: 0 !important;
           }

       } 
    
       .admin-settings-clients .th {letter-spacing: -1px; color: #49495B !important;}
       .admin-settings-clients .cursor {cursor: pointer; color: #49495B;}
       .admin-settings-clients .add-pharmacy , 
        .admin-settings-clients .label-text-billing {color: #49495B;}
       .admin-settings-clients .label-text { font-size: 16px; color: #49495B;  }
       .admin-settings-clients .colors .form-control { background-color: #FFFFFF !important;}  
    /* Admin settings Client Screen CSS End */
/* Admin settings Medication screen CSS END */




/****************** Admin settings patient screen css start***********/

.admin-settings-patient .patient-approved {
    background-color: #e1e1e1;
}

.admin-settings-patient .fa-search {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #CCD2E3;
}

.admin-settings-patient .input-field {
    background-color: transparent;
    border-radius: 10px !important;
}

.admin-settings-patient table {
    background-color: #E1E1E1 !important;
}

.admin-settings-patient .btn-success {
    font-size: 15px;
    border-radius: 7px;
    background-color: #00BD1E;
    border-color: #00BD1E;
    color: #FFFFFF;
}

.admin-settings-patient .settings-patients-card {
    padding-left: 2rem !important;
    border-left: 1px solid #B3B3B3;
    height: calc(100vh - 80px);
    padding-top: 1.5rem !important;
}

@media (max-width: 991.98px) {
    .admin-settings-patient .settings-patients-card {
        padding-right: 0px !important;
        border-left: none !important;
        padding-left: 0px  !important;

    }
}
.admin-settings-patient .patient-table {
    padding-right: 2rem !important;
}
@media (max-width: 991.98px) {
    .admin-settings-patient .patient-table {
        padding-right: 0 !important;
    }

}

.admin-settings-patient th {
    display: flex;
    align-items: center;
}

.admin-settings-patient .calendar-svg , .admin-settings-patient .phone-svg {
    position: absolute;
    right: 30px;
    border-radius: 4px !important;
    pointer-events: none;
    color : #ccd2e3 !important;
}
.admin-settings-patient #Layer_4 {
    fill: #CCD2E3;
}

.admin-settings-patient #comm-17_chat {
    fill: #ff8918;
}

.admin-settings-patient #phone {
    fill: #00bd1e;
}

.admin-settings-patient .icons {
    margin-top: -14px;
}

.admin-settings-patient .patients-text-color {
    color: #49495B;
}

.admin-settings-patient .icons-rotate {
    transform: rotate(-90deg) !important;
}

.admin-settings-patient .user-info {
    font-weight: 800;
    font-size: 14px;
    line-height: 84.68%;
    display: flex;
    align-items: center;
    letter-spacing: -0.065em;
    color: #49495B;
}

.admin-settings-patient .patient-history-text {
    font-weight: 600;
    font-size: 38px;
    line-height: 89.18%;
    display: flex;
    align-items: center;
    letter-spacing: -0.065em;
    text-decoration-line: underline;
    color: #49495B;
    padding-left: 13px;
}

.admin-settings-patient .patient-history {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.06em;
}

.admin-settings-patient .patient-history-bg {
    background-color: #ffffff;
}

.update-profile .password-pencil {
    position: absolute;
    top: 8px !important;
    right: 10px !important;
    cursor: pointer;
}

.update-profile .sign-pad {
    min-height: 100px; background-color: #E1E1E1;
  }

  .update-profile .sign-pad img {
  height: 100px; width: 300px;
  }


/* Previous Notes & ACtion Log */
.admin-settings-patient .action-log {
    background-color: #c4c4c4;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100.68%;
}

.admin-settings-patient .date-created {
    font-size: small !important;
    letter-spacing: -1px !important;
}

/* timeline */

/* Timeline holder */
.admin-settings-patient ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
}

 /* Timeline vertical line */
 .admin-settings-patient ul.timeline:before {
    content: ' ';
    background: #C4C4C4;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

.admin-settings-patient li.timeline-item {
    margin: 20px 0;
}

/* Timeline item arrow */
.admin-settings-patient .timeline-arrow {
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid #C4C4C4;
    border-bottom: 0.5rem solid transparent;
    display: block;
    position: absolute;
    left: 2rem;
}

/* Timeline item circle marker */
.admin-settings-patient li.timeline-item::before {
    content: ' ';
    background: #C4C4C4;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #C4C4C4;
    left: 11px;
    width: 14px;
    height: 14px;
    z-index: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.admin-settings-patient .timeline-item {
    background-color: #C4C4C4;
    border-radius: 7px;
}

/****************** Admin settings patient screen css end***********/



/******************* Admin settings providers screen css start ***************** */




.family-member-dropdown .dropdown-list ul ,
.setting-provider-dropdown .dropdown-list ul ,
.registration-two .patient-registration-dropdown .dropdown-list ul ,
.provider-profile-availability .availability-dropdown-scroller .dropdown-list ul ,
.admin-settings-clients .doctors-assigned-dropdown .dropdown-list ul , .update-profile .dropdown-list ul{
    max-height: none !important;
    
}

.add-family-member .family-member-dropdown .dropdown-list , 
.admin-settings-provider .setting-provider-dropdown .dropdown-list ,
.registration-two .patient-registration-dropdown .dropdown-list ,
.provider-profile-availability .availability-dropdown-scroller .dropdown-list ,
.admin-settings-clients .doctors-assigned-dropdown .dropdown-list , .update-profile .dropdown-list{
    max-height: 200px !important;
    overflow-y: auto !important;
    z-index: 1;

}

.admin-settings-provider .modal-dialog .btn-primary {
        color: #fff;
        font-weight: 700;
        background-color: #00b200;
        border-color: #00b200;
        border-radius: 10px;
}
.admin-settings-provider .modal-dialog .btn-outline-primary {
    color: #00b200;
    font-weight: 700;
    background-color: transparent;
    border-color: #00b200;
    border-radius: 10px;
}
.admin-settings-provider .modal-dialog .btn-outline-primary:hover {
    color: #fff !important;
    background-color: #00b200 !important;
    border-color: #00b200 !important;
}

.admin-settings-provider .modal-dialog .btn-primary:hover {
    color: #fff !important;
    background-color: #00b200 !important;
    border-color: #00b200 !important;
}

.admin-settings-provider .modal-dialog .btn-primary:focus {
    color: #fff !important;
    background-color: #00b200 !important;
    border-color: #00b200 !important;
}


.admin-settings-provider .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: #FFFFFF
    !important;
}
.admin-settings-provider table {
    background-color: #E1E1E1 !important;
}

.admin-settings-provider .bg-c4c4c4 {
    background-color: #E1E1E1;
    border-radius: 5px;
    color: #49495B;
}

.admin-settings-provider .provider-modal-text {
    color: #49495B;

}

/* right side */

@media (max-width: 475.98px) { 
    .admin-settings-provider .deactivate-message {
        display: initial !important;
    }

}


    

.admin-settings-provider .updateprofile h2  {
    font-size: 24px !important;
}
.admin-settings-provider .img-size{
    border-radius: 40px;
    object-fit: cover;
}
.admin-settings-provider .update-address , .admin-settings-provider .tbl-text {line-height: 0.9;color: #49495B; }

.admin-settings-provider .btn-provider-activate{
    border-radius: 10px !important;
    font-size: 14px !important;
}

.admin-settings-provider .pass-font{
    font-size: 16px; 
    font-weight: 700; 
    letter-spacing:-1px;
}
.admin-settings-provider .pencil-icon{ 
    position: absolute; 
    right: 14px;
    bottom: 78px;
    cursor: pointer;
}

.admin-settings-provider .client-card .card {border-radius: 5px;}
.admin-settings-provider .card-lables {background-color: aqua; font-size: 16px; font-weight: 900;}

.admin-settings-provider .btn-primary.btn-sm{
    background-color: #E00000 !important;
    border-radius: 10px;
    font-weight: 900 !important;
    border: #E00000 !important;
}
.admin-settings-provider .btn-secondary.btn-sm {
    background-color: #38A9E9 !important;
    border-radius: 10px;
    font-weight: 900 !important;
    border: #2196F3 !important;
    color: #49495B !important;

}
/* switch toggle */
.admin-settings-provider .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
  }
  
  .admin-settings-provider .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .admin-settings-provider .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .admin-settings-provider .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .admin-settings-provider input:checked + .slider {
    background-color: #49495B;
  }
  
  .admin-settings-provider input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  .admin-settings-provider  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  .admin-settings-provider .slider.round {
    border-radius: 34px;
  }
  
  .admin-settings-provider .slider.round:before {
    border-radius: 50%;
  }

  .admin-settings-provider .btn-primary:focus {
    border-color:transparent !important;
}

.admin-settings-provider .btn-secondary:focus {
    border-color:transparent !important;
}
.admin-settings-provider .desc-icon {
    position: absolute;
    right: 8px;
    top: -1px;
    pointer-events: none;
    font-size: 27px;
  }
/* dropdown */
.admin-settings-provider .provider-dropdown .dropdown .btn-secondary {
    color: #49495b!important;
    background-color: transparent !important;
    border: 1px solid #ced4da!important;
    display: flex;
    gap: 30px;
    border-radius: 10px;
}

.admin-settings-provider .red {color:#E00000 !important;}
.admin-settings-provider .green{color: #00BD1E !important;}

/* Add Provider Modal */
@media (min-width: 992px) {
    .admin-settings-provider  .modal-xl .modal-body {
        padding: 2rem 10rem 1rem 10rem !important;
    }
    }

.admin-settings-provider .popup-profile-pic {  
    font-size: 18px;
    letter-spacing: -1px;
    font-weight: 800;
    color: #49495b;
    cursor: pointer;
}
.admin-settings-provider .revert-modal .modal-content{ background-color: #e1e1e1!important;
    border: none;
    border-radius: 16px;
} 


.admin-settings-provider .revert-modal .btn-close {
    border-radius: 50%;
    border: 1px solid;
    font-size: 10px;
    font-weight: 800 !important; 
}

.admin-settings-provider .modal-form {background-color: #E1E1E1; 
    border-radius: 5px !important;
}
.admin-settings-provider .State {
    position: absolute;
    right: 17px;
    top: 9px;
    pointer-events: none;
}
.admin-settings-provider .modal-form input {
border-radius: 10px;
border: none !important;
}
.admin-settings-provider .modal-backdrop {
    background-color: #fff !important;
} 
.admin-settings-provider .modal-dialog svg {
    fill: #49495b !important;
}

.admin-settings-provider .modal-dialog .fa-sort-desc  {
  color: #CCD2E3 !important;
}

/* model end */
.admin-settings-provider .textarea-svg {
    position: absolute;
    right: 20px;
}

.admin-settings-provider .pencil-svg {
        position: absolute;
        right: 6px;
}
    
.admin-settings-provider textarea {
    resize: none;
    min-height: 95px;
}
.admin-settings-provider .modal-xl .modal-content  {
    margin-top: 5rem !important;
}

.admin-settings-provider .message-text .form-control {
    background-color: #fff !important;
}
.admin-settings-provider .name-option select.form-control {
    color: #000000;
}
.admin-settings-provider .message-modal .btn-success {
border-radius: 10px;
}
.admin-settings-provider .modal-header span{
    position: absolute;
    right: 8px;
}
.admin-settings-provider .message-modal .form-control{
 border-radius: 10px !important;
}

.admin-settings-provider .modal-content.content-popop {
    background-color: #E1E1E1!important;
    border: none;
    border-radius: 16px;
}

.admin-settings-provider .providers-vaccation{
    margin-left: 13px !important;
} 

.admin-settings-provider .provider-dob{
    margin-left: -6px !important;
}

.admin-settings-provider .provider-data {
    margin-left: 7px !important;
}


@media (min-width: 992px) { 
    .admin-settings-provider .left-border {border-right: 1px solid #B3B3B3;
         height: calc(100vh - 80px);
        }
    .admin-settings-provider .left-table {padding-right: 2rem;}
 }

 @media (min-width: 1025.99px) { 
    .admin-settings-provider .profile-detail {padding-left: 2rem;}
 }
 @media (max-width: 992px) { 
    .admin-settings-provider .img-size {
        width: 100%;
        border-radius: 50px;
        height: 100%;
    }
 }

 .admin-settings-provider .form-check-label {
    letter-spacing: -1px;
}

.admin-settings-provider input {border-radius: 10px;}
 .admin-settings-provider .provider-details {
     font-size: 12px;
     letter-spacing: -1px;
 }
.admin-settings-provider .sign-svg {
    position: absolute;
    right: 19px;
    top: 20px;
}
.admin-settings-provider .pen-svg {
    position: absolute;
    right: 3px;
    top: 20px;
 }
 .admin-settings-provider .side-class{margin-left: 202px;}

.admin-settings-provider .ml-n10{
    margin-left: -10px;
}
.admin-settings-provider .close{
    cursor: pointer;
    font-weight: 600;
    font-size: 28px;
    margin-left: 10px;
}
@media (max-width:991px){
    .admin-settings-provider .new-rx{
        padding-left: 0;
        justify-content: start !important;
    }
}
.admin-settings-provider .details-form {
padding-right: 25px;
}
/* ***************** Admin settings providers screen css end *******************/


/************ Admin Provider Availability CSS start  ****************/
 .provider-profile-availability {
    color: #49495B;
  }
  .provider-profile-availability .right-border {
    border-right: 2px solid #d6d9dc;
    height: auto !important;
  }
  .provider-profile-availability h3 {
    font-size: 35px;
    letter-spacing: -1px;
  }
  .provider-profile-availability  pre {
    background-color: #f5f5f5;
    padding: 15px;
  }
  .provider-profile-availability  .fa-caret-up {
    position: absolute;
    top: 36px ;
    right: -28px;
     cursor: pointer;
  }
  
  .provider-profile-availability .fa-caret-down {
    position: absolute;
    bottom: 83px;
    right: -28px;
    cursor: pointer;
  }
  
  .provider-profile-availability .circle {
    height: 20px;
    font-size: 24px;
    width: 20px !important;
    color: #00bd1e !important;
    font-weight: 500;
    background-color: #fff !important;
    border-radius: 50px;
    display: flex;
    position: absolute;
    top: 35px;
    right: -20px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  @media (max-width:991px){
    .provider-profile-availability .circle {
        right: -10px;
      }
  }
  .provider-profile-availability .icon-plus {
    height: 14px !important;
    font-size: 20px !important;
    width: 14px !important;
    color: #fff !important;
    font-weight: 500;
    background-color: #00bd1e;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px !important;
    left: -5px !important;
    padding: 8px !important;
    cursor: pointer;
  }

  
  @media (max-width:991px){
    .provider-profile-availability .right-border{
      border-right: 0 !important;
      height: auto !important;

    }
  }
/************ Admin Provider Availability CSS end  ****************/



/*********** household history start ********************/



.house-hold-history .household-history {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.06em;
}

.house-hold-history .household-history-bg {
    background-color: #ffffff;
}

.house-hold-history .tr-active {
    color: #285cb7 !important;
}

.house-hold-history .table>:not(caption)>*>* {
    background-color: #E1E1E1 !important;

} 

.house-hold-history .table-striped>tbody>tr:nth-of-type(even)>*  {
    --bs-table-accent-bg: rgb(255 255 255) !important; 
}

.house-hold-history .household-history {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.06em;
}

.house-hold-history .household-history-bg {
    background-color: #ffffff;
}

.house-hold-history .household-approved {
    background-color: #e1e1e1;
}

.house-hold-history .border-left-1 {
    border-left: 2px solid #c4c4c4 !important;
}

.house-hold-history .household-pdf {
    padding: 3.5rem 3rem;
    height: calc(100vh - 150px);
    margin-top: 3rem;
    background-color: #6a6a6a;
    overflow: auto;
}

.house-hold-history .card-bg-color {
    background: #fff !important;
}
    @media (max-width: 992px) {
        .registration-two .custom-col-lg-12 {
            display: flex;
            justify-content: center;
            align-items: center;
       
        }
    }

    @media (max-width: 992px) {
        .complete-register-profile {
            display: flex;
            justify-content: center;
       
        }
    }


@media (max-width: 992px) {
    .house-hold-history .border-left-1 {
        border-left: none!important;
   
    }
}

/**************** household history end ***************/



/* Apointment component media query */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .appointment-images {
        width: 100%;
        height: auto !important;
        object-fit: cover !important;
        border-radius: 40px !important;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
    .consult {
        margin-top: 12px !important;
    }
}

@media (max-width: 1200px) {
    .past-consults {
        padding-top: 1.5rem !important;
    }
}

/* Consulttype */
.see-more {
    position: relative;
}

.see-more-cursor {
    cursor: pointer;
}


/* header */

.navbar {
    background-color: #285cb7;
    height: 80px;
}

.dropdown .btn-secondary {
    color: black ;
    background-color: #fefefe ;
    border: 1px solid #e6e6e6 ;
    display: flex;
    gap: 30px;
}

.dropdown-toggle::after {
    float: right !important;
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    color: #cbd1e1 !important;
    border-top: 0.6em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    border-left: 0.5em solid transparent !important;
    margin-top: 8px !important;
}

/* signup page */
.register-account {
    top: 120px;
}

.register-card.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color:#C4C4C4BF !important;
    background-clip: border-box;
    border: 1px solid #C4C4C4BF !important;
    border-radius: 17px;
    margin-bottom: 150px !important;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.5rem !important;
    line-height: 1.5;
    border-radius: 0.9rem !important;
}


.form-check.register-checkbox {
    left: 40px !important;
}

.form-group.email-address {
    margin-top: -15px !important;
}


/* Update Profile Component */
.update-profile .img-size{
    height: 350px;
    width: 350px;    
    border-radius: 40px;
    object-fit: cover !important;
}


.update-profile .text-color, .update-profile P , .update-profile h4 {
    color:#49495B;
}
.update-profile .bg-c4c4c4 {
    background-color: #e1e1e1;
}
.update-profile .signature-cross-icon{
    font-size: 28px;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: -116px;
}
.update-profile .main-edit-icon{
    position: absolute;
    top: -10px;
    right: -4px;
}

.update-profile .pencil-icon{
    position: absolute;
    top: 5px;
    right: 35px;
}
.update-profile .sign-upload-icon {
    position: absolute;
    right: 10px;
    top: 2px;
}
.update-profile .sign-check-icon {
    position: absolute;
    right: 48px;
    top: -108px;
}
.update-profile .sign-undo-icon {
    position: absolute;
    right: 24px;
    top: -106px;
}

.update-profile .pass-change-cross-icon
{
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 10px;
}

.update-profile .checkmark{
    position: absolute;
    right: 0;
}

.update-profile .checkmark{
    position: absolute;
    right: 0;
}
.update-profile .password-btn i {
    position: absolute;
    top: 8px;
    right: 30px;
}


.update-profile .password-check {
    position: absolute;
    right: 45px;
    top: 3px;

}
.update-profile .password-btn {
    border: none;
    background: transparent;
}

.update-profile .input-file input , .admin-settings-provider .input-file input{
    opacity: 0; 
    position: absolute;
}

.update-profile .form-control-bg-color{background-color: #e1e1e1 !important; }

.update-profile textarea {
    resize: none;
    height: 100px;
}
.update-profile .update-address{
    line-height: 1.2; letter-spacing: -1px;
}
.update-profile .update-address {
    line-height: 1.2;
    letter-spacing: -1px;
}

.update-profile .detail-section-icons {
    position: absolute;
    right: 8px;
    cursor: pointer;
}

.update-profile.font-size-update-picture {
    font-size: 13px;
    display: flex;
    justify-content: center;
}

.update-profile .border-0 {
    border: none !important;
}

.update-profile .update-profile-bg {
    padding: 0 6rem !important;
}

@media (max-width: 768px) {
    .update-profile .update-profile-bg {
        padding: 0 !important;
    }
}

@media (max-width: 1440px) {
    .update-profile .img-size {
width: auto;

    }
}


@media (max-width: 992px) {
    .update-profile .img-size {
        height: 100% !important;
        width: 100% !important;
    }
}

@media (max-width: 991px) {
    .patient-list .doc-profile-img img {
        height: 100% !important;
        width: 100% !important;     
        display: flex;
        justify-content: center;
    }
}


@media (max-width: 824px) and (min-width: 765.98px) { 
    .provider-update-password .col-md-4 {
        width: 49.9% !important;
    }
 }

/* Update Profile Component  end */




.password-border-radius {
    border-radius: 10px;
}


/* max width */

.max-width {
    max-width: 1024px;
    margin: 0 auto;
}

@media (min-width: 991px) and (max-width: 1199px) {
    .household .col-lg-3 {
        width: 50% !important;
    }
}

.st0 {
    fill: transparent !important;
}


/* ng multi select dropdown */

 .add-provider-availability .multiselect-dropdown .dropdown-btn ,  
 .provider-availabilit-dropdown .dropdown-menu.show {
    border: 1px solid #d0d0d3!important;}

 .add-provider-availability .dropdown-list , .provider-availabilit-dropdown .dropdown-menu.show {
        z-index: 5 !important;}
    
.multiselect-dropdown .dropdown-btn {
    border: 1px solid #ffffff !important;
    background: white !important;
    border-radius: 10px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
    text-decoration: none;
    display: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    color: #d1d2d3 !important;
    border-color: #ccd2e3 transparent !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #00bd1e !important;
    background: #00bd1e !important;
    color: #000 !important;
    border-radius: 9px !important;
    max-width: none !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
    font-size: 14px !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked+div:before {
    background: #00bd1e !important;
}

.multiselect-item-checkbox input[type="checkbox"]+div:before {
    box-sizing: content-box;
    border: 2px solid #00bd1e !important;
}


/* chips css */

.disableKeyTermsInput,
.input-tag {
    border: none;
    background: #ffffff !important;
}

.keyTerms {
    background: #fff;
    border-radius: 10px !important;
}

.editor-tag {
    cursor: text;
    font-size: 16px;
    border-radius: 5px;
    background: white;
}

.editor-tag .tag-item {
    border-radius: 10px;
    height: auto;
    font-size: 17px;
    display: inline-block;
    background: #0e6efd;
    color: #d9e7ff;

}

.editor-tag input {
    outline: transparent !important;
    border-radius: 10px;
    border-color: #cccccc;
}


/* chips css end */

.modal-backdrop {
    background-color: #e1e1e1 !important;
}


/* buttons css */

.btn-primary:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}

.btn-secondary:focus {
    box-shadow: none !important;
    color: #fff !important;
    background-color: #00bd1e !important;
    border-color: transparent !important;
}

 .header-dropdown .btn-secondary:focus , .admin-patient-list .btn-secondary:focus ,
 .admin-settings-clients .btn-secondary:focus {
    box-shadow: none !important;
    color: #000 !important;
    background-color: #fff !important;
    border-color: transparent !important;
}



/* buttons css end */

.cross-icon {
    background-image: url("./assets/images/close_ring.png");
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.cross-icon2 {
    background-image: url("./assets/images/close_ring.png");
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
}


/* availibility screen*/

.availability .multiselect-item-checkbox input[type="checkbox"]:checked+div:before {
    background: #38a9e9 !important;
}

.availability .multiselect-item-checkbox input[type="checkbox"]+div:before {
    box-sizing: content-box;
    border: 2px solid #38a9e9 !important;
}

.availability .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #38a9e9 !important;
    background: #38a9e9 !important;
    color: #000 !important;
}

.availability .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
    font-size: 18px !important;
}

.provider-availability .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #ffffff !important;
    background: #ffffff !important;
    color: #000 !important;
}

.provider-availability .multiselect-item-checkbox input[type="checkbox"]+div:before {
    border: 2px solid #75787b9e !important;
}

.provider-availability .multiselect-item-checkbox input[type="checkbox"]:checked+div:before {
    background: #285cb7 !important;
}

.provider-availability .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
    font-size: 18px !important;
}


/* Weekly schedule calander */

.cal-week-view {
    background-color: #fff0 !important;
    border-top: solid 1px #e1e1e100 !important;
}

.cal-week-view .cal-ends-within-day .cal-event {
    background-color: #ffc029 !important;
    border-color: #ffc029 !important;
    border-radius: 13px !important;
}

.cal-week-view .cal-starts-within-day .cal-event {
    background-color: #ffc029 !important;
    border-color: #ffc029 !important;
    border-radius: 13px !important;
}

.cal-week-view .cal-all-day-events {
    display: none !important;
}

.cal-week-view .cal-day-headers {
    border: none !important;
}

.cal-week-view .cal-time-events {
    border: none !important;
}

.cal-week-view .cal-header.cal-weekend span {
    color: #49495b !important;
    font-weight: 800 !important;
}

.cal-week-view .cal-day-headers span {
    color: #49495b !important;
    font-weight: 800 !important;
    opacity: unset !important;
}

.cal-week-view .cal-day-headers .cal-header:first-child {
    color: #49495b !important;
    font-weight: 800 !important;
    opacity: unset !important;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    color: #49495b !important;
    font-weight: 800 !important;
    opacity: unset !important;
}

.cal-week-view .cal-current-time-marker {
    background-color: #ea433400 !important;
}

.cal-event-title {
    display: none !important;
}

.cal-week-view .cal-time {
    font-weight: 800 !important;
}

.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
    background: #f5fbff !important;
}

.data-table.alert-messages {
    position: fixed;
    top: 50px;
    margin-top: 4rem;
    left: 50%;
    z-index: 100000;
    transform: translate(-50%, -50%);
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #0d6efd !important;
    background: #0d6efd !important;
    color: #fff !important;
    margin-bottom: 2px !important;
    max-width: max-content !important;
}

.multiselect-item-checkbox input[type="checkbox"]+div:before {
    color: #0d6efd !important;
    border: 2px solid #0d6efd !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked+div:before {
    background: #0d6efd !important;
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index:1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading .spinner-border {
    width: 5rem;
    height: 5rem;
    color: #285cb7;
}

.loading>p {
    margin-top: 30px;
    color: #285cb7;
    font-size: 20px;
}

.mat-calendar-body-selected {
    background-color: #ffc029 !important;
    color: #353535 !important;
}

.form-control-time {
    font-size: 13px !important;
    background-color: #c4c4c4 !important;
    border-radius: 4px !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.login-screen .form-check-input {
    border: 1px solid #49495b !important;
}

.signup-screen .form-check-input {
    border: 1px solid #49495b !important;
}

.calander-consult-type .cal-month-view .cal-header .cal-cell {
    display: none !important;
}

.calander-consult-type .calander .btn-group {
    display: flex !important;
    justify-content: space-around;
}

.calander-consult-type .calander .btn-group h6 {
    margin-top: 2px !important;
    margin-bottom: 0.5rem;
    font-weight: 800 !important;
    line-height: 1.2;
    font-size: 24px !important;
    letter-spacing: -1px !important;
}

.calander-consult-type .calander .btn-group span {
    cursor: pointer !important;
}

.calander-consult-type .calander .cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: #e1e1e1;
    border-bottom: none;
}

.calander-consult-type .calander .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: #e1e1e1;
    border-right: none !important;
}

.calander-consult-type .calander .cal-month-view .cal-day-cell:not(:last-child) {
    border-right: 1px solid;
    border-right: none !important;
}

.calander-consult-type .calander .cal-month-view .cal-day-cell {
    min-height: 60px;
}

.calander-consult-type .calander .cal-month-view .cal-days {
    border-color: #e1e1e1;
    border: none !important;
}

.calander-consult-type .calander .cal-month-view .cal-days {
    border: none !important;
    border-bottom: 0;
}

.cal-month-view {
    background-color: transparent !important;
}

.calander-consult-type .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #49495b !important;
}

.calander-consult-type .calander {
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    border-radius: 20px;
    padding: 5px;
}

.calander-consult-type .calander .cal-month-view .cal-cell-top {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.calander-consult-type .calander .cal-month-view .cal-month-view .cal-day-number {
    font-size: 1.2em !important;
    opacity: 1 !important;
}

.calander-consult-type .calander .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2em !important;
}

.calander-consult-type .calander .cal-month-view .cal-day-cell.cal-today {
    background-color: transparent ;
}

.shared .calander {
    border: 1px solid #ebebeb;
    background-color: rgba(235, 235, 235, 0) 100%;
    border-radius: 20px;
    padding: 5px;
}

.provider-calander .calander {
    border: none !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.shared .calander .cal-month-view .cal-day-cell {
    min-height: 56px !important;
}

.shared .calander .cal-month-view .cal-days .cal-cell-row {
    height: 38px !important;
}

.shared .calander .cal-month-view .cal-day-cell {
    min-height: 38px !important;
}

.shared .cal-month-view .cal-day-number {
    font-weight: 400 !important;
    color: #49495b !important;
    font-size: 14px !important;
}

.shared .calander .cal-month-view .cal-day-cell.cal-today {
    font-weight: 400 !important;
    color: #49495b !important;
    font-size: 14px !important;
    background-color: #ffc029 !important;
    border-radius: 27px !important;
}
.cal-month-view .cal-day-number{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}

/* Patient Record */

.patient-record {
    font-size: 22px !important;
    letter-spacing: -1px !important;
    color: #2e5fbd !important;
}

.medical-record {
    color: #2e5fbd !important;
}

.p-record-border-bottom {
    border-bottom: 3px solid #2e5fbd !important;
}

.provider-calander .calander .cal-month-view .cal-days .cal-cell-row {
    height: 30px !important;
}

.provider-calander .calander .cal-month-view .cal-day-cell {
    min-height: 30px !important;
}

.provider-calander .cal-month-view .cal-day-number {
    font-weight: 400 !important;
    color: #49495b !important;
    font-size: 14px !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.provider-calander .calander .cal-month-view .cal-day-cell.cal-today {
    background-color: #ffc029 !important;
    border-radius: 27px !important;
    color: #49495b !important;
    font-size: 14px !important;
}

.scroller::-webkit-scrollbar-thumb {
    background: #7c7b7b;
    border-radius: 10px;
}
.intake-patient-list-scroller {
    max-height: calc(100vh - 336px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}


.pharmacy-postal.patient-list-scroller {
    max-height: calc(100vh - 540px) !important;
}
.patient-questions-scroller {
    height: calc(100vh - 350px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.patient-list-scroller {
    /* max-height: calc(100vh - 460px) !important; */
    max-height: calc(100vh - 390px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.expand-sections-patient-list-scroller {
    /* max-height: calc(100vh - 447px) !important; */
    max-height: calc(100vh - 466px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.notification-patient-list-scroller{
    max-height: calc(100vh - 260px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.edit-household-patient-list-scroller {
    max-height: calc(100vh - 235px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.family-member-patient-list-scroller {
    max-height: 78px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.patient-regist-patient-list-scroller{
    max-height: calc(100vh - 200px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}


/* dropdoown scroller start*/


.dropdown-patient-list-scroller {
    max-height: 180px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 100% !important;
}

/* dropdoown scroller end*/

.provider-dashboard-scroller {
    max-height: calc(100vh - 160px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.states-patient-list-scroller{
    height: 200px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.add-member-patient-list-scroller{
    max-height: calc(100vh - 230px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;

}

.report-builder-patient-list-scroller {
    max-height: calc(100vh - 160px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.notes-collapse-patient-list-scroller{
    max-height: calc(100vh - 452px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.medicine-patient-list-scroller{
    max-height: calc(50vh - 250px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.scroller::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: transparent;
    scroll-margin-top: 10px;
}

.medication-patient-list-scroller {
    max-height: calc(100vh - 345px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.pharmacies-patient-list-scroller {
    max-height: calc(100vh - 300px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.pharmacies-patient-list-scroller-right {
    max-height: calc(100vh - 250px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.dropdown-patient-list-scroller::-webkit-scrollbar-track {
    background: #fff;
    width: 100%;
  }
.custom-scroller{
    mask-image: linear-gradient(to top, #c4c4c4, #aaa),
    linear-gradient(to left, #c4c4c4 17px, #aaa 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, #c4c4c4, #aaa),
    linear-gradient(to left, #c4c4c4 17px, #aaa 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.pharmacies-patient-list-scroller-right:hover {
    -webkit-mask-position: left top;
  }
  
  @keyframes background {
    from {
      background: pink;
    }
    to {
      background: #c0d6ff;
    }
  }
  
  .wrapper {
    float: left;
    animation: background 5s infinite alternate;
  }
.provider-patient-list-scroller {
    max-height: calc(100vh - 300px) !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}
@media (min-height: 1442px)  { 
    .provider-patient-list-scroller {
        max-height: calc(100vh - 680px) !important;
        overflow-y: auto !important;
        overflow-x: auto !important;
    }
 }
 @media (min-height: 1822px)  { 
    .provider-patient-list-scroller {
        max-height: calc(100vh - 900px) !important;
        overflow-y: auto !important;
        overflow-x: auto !important;
    }
 }
.setting-patient-list-scroller-right {
    /* max-height: calc(100vh - 553px) !important; */
    max-height: calc(100vh - 530px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.setting-patient-list-scroller-history {
    max-height: 200px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.provider-patient-list-scroller-right{
    max-height: calc(100vh - 190px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.reports-patient-list-scroller{
    max-height: calc(100vh - 335px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.report-builder-patient-scroller{
    max-height: calc(100vh - 335px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.admins-patient-list-scroller{
    max-height: calc(100vh - 300px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.admins-patient-list-scroller-right{
    max-height: calc(100vh - 261px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}


.client-patient-list-scroller {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.__talkjs_launcher {
    background-color: #295cb7 !important;
    bottom: 90px !important;

}
.__talkjs_popup {
    bottom: 130px !important;
}

.header-navbar .navbar-collapse.collapse.show {
    z-index: 1;
}
.header-navbar .navbar{
    z-index: 10;

}

.header-navbar {
    position: fixed !important;
    width: 100% !important;
    z-index: 11 !important;
    top: 0 !important;
}
.header-navbar .navbar-light .navbar-toggler {
   color: rgb(0 0 0 / 0%)!important; 
    border-color: #084298;
    background: #f8fbff !important;

}
.header-navbar .navbar-collapse{padding-right: 5rem !important;}

.header-navbar svg {
    fill: #fff;
}
.header-navbar .logo {
    height: 50px;
}
.header-logo {margin-left: 5rem !important;}

@media (max-width: 768px) { 
    .header-navbar .logo {
        display: block;
        height: 50px;
    }

 }
 @media (max-width: 991px) { 
    .header-logo {margin-left: 1rem !important;}
 }

 .header-navbar .custom-btn-profile{
     background-color: transparent !important;
     border: 0 !important;
 }
 .header-navbar .custom-btn-profile.dropdown-toggle:after {
    display: none !important;
}
.header-navbar .custom-btn-profile:focus {
    box-shadow: none !important;
}
.header-navbar .ac-setting{
    background-color: #C5D2EA !important;
    border-radius: 0px 0px 10px 10px !important;
    margin-right: -110px !important;
    padding: 14px  !important;
}
.header-navbar .signout{
    font-size: 25px !important;
    margin-left: 15px !important;
}


.header-navbar .message-notifier
{
    position: absolute;
    right: 0;
    left: 26px;
    background: red;
}

.header-navbar .profile-img img {
    height: 45px;
    border-radius: 50%;
}
.header-navbar .vacation-btn-on{
    border-radius: 7px;
    height: 38px;
    right: 500px;
    border-style: none;
    font-weight: 700;
    font-size: 14px;
    width: 205px;
    background-color: #FFBF17;
}
.header-navbar  .vacation-btn-off{
    border-radius: 7px;
    height: 38px;
    right: 500px;
    font-weight: 700;
    font-size: 14px;
    border-style: none;
    width: 205px;
    background-color: #C8DDE9A3;
    
}

@media (max-width : 992px) {
    .vacation-btn-on {
        margin: 0.5rem 0 0.5rem 0  !important; 
   }

    .vacation-btn-off {
        margin: 0.5rem 0 0.5rem 0  !important; 

    }
}

/************Header CSS end ************/

/*********** HIPPA CSS start ***********/

.hippa-complaint svg {
    height: 100px;
    fill: #285cb7 !important;
}

/*********** HIPPA CSS end ***********/


/* Provider Profile css starts */

.provider-profile-availability {
    color: #49495b;
    letter-spacing: -1px;
}
 .provider-availabilit-dropdown .btn-secondary:hover {
    background-color : #fff !important;
    color: #49495b !important;
    border: 1px solid #e6e6e6 !important;
}
.provider-availabilit-dropdown .btn-secondary:active:focus {
    border: 1px solid #e6e6e6 !important;
 }

.multiselect-dropdown .dropdown-btn .selected-item:hover {
    box-shadow: none !important;
}

.provider-profile-availability h3 {
    font-size: 39px;
    margin: 0 0 10px;
}

.provider-profile-availability pre {
    background-color: #f5f5f5;
    padding: 15px;
}

.provider-profile-availability .fa-caret-up {
    position: absolute;
    top: 36px;
    right: -28px;
    cursor: pointer;
}

.provider-profile-availability .fa-caret-down {
    position: absolute;
    bottom: 83px;
    right: -28px;
    cursor: pointer;
}


/** No CSS for this example */

::ng-deep mat-form-field {
    width: 100%;
}


::ng-deep .mat-datepicker-content {
    background: linear-gradient( 180deg, #ebebeb 0%, rgb(235 235 235 / 92%) 100%) !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    margin-top: 10px;
}

::ng-deep .mat-calendar-body-cell.selected>.mat-calendar-body-cell-content,
::ng-deep .mat-calendar-body-cell.selected:hover>.mat-calendar-body-cell-content,
::ng-deep .mat-calendar-body-cell.selected>.mat-calendar-body-cell-content:hover {
    background-color: #ffc029 !important;
    color: #353535 !important;
}


/* single select datepicker */

.provider-profile-availability .mat-card:not([class*="mat-elevation-z"]) {
    background: #f3f3f3 !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.provider-profile-availability .fa-sort-desc {
    position: absolute;
    right: 18px;
    top: 5px;
    color: black !important;
}
.provider-profile-availability article {
    position: relative;
    width: 28px;
    height: 28px;
    margin: 5px;
    float: left;
    border-radius: 50%;
    border: 2px solid #49495b;
    box-sizing: border-box;
}

.provider-profile-availability article div {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    transition: 0.5s ease;
    width: 26px;
    margin-left: -13px;
    height: 26px;
    margin-top: -1px;
}

.provider-profile-availability article input {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
    cursor: pointer;
}

.provider-profile-availability .every input[type="checkbox"]:checked~div {
    background-color: #ffc029;
    border-radius: 50%;
}


/* single select end */


/* Provider Profile css Ends */


/* Patient list details css starts*/

.provider-patient-list-detail .table {
    background-color: #ffffff !important;
}

.provider-patient-list-detail .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #e1e1e1 !important;
}


.provider-patient-list-detail .form-control:focus {
    color: #212529;
    background-color: none !important;
    border-color: none !important;
    box-shadow: none !important;
}

.provider-patient-list-detail .form-control {
    background-color: #ffffff00 !important;
    border: 1px solid #ced4da !important;
}

 ::placeholder {
    color: #aeb1b3 !important;
}

.provider-patient-list-detail .arrow-up {
    position: relative;
}

.provider-patient-list-detail .arrow-up .fa {
    position: absolute;
    left: 0px;
    top: -12px;
    color: #cbd1e1;
}

.provider-patient-list-detail .client-dropdown .dropdown .btn-secondary {
    gap: 50px !important;
}

.provider-patient-list-detail .Status-dropdown .dropdown .btn-secondary {
    gap: 50px !important;
}

.provider-patient-list-detail .client-dropdown {
    margin-right: 5px !important;
}


.provider-patient-list-detail .fa {
    cursor: pointer;
}

.provider-patient-list-detail .intake-card {
    background-color: #e1e1e1 !important;
}

.provider-patient-list-detail .intake-icon {
    position: absolute;
    right: 6px;
    top: 1px;
}

.provider-patient-list-detail .medicine-name .pencil-icon {
    position: absolute;
    right: 5%;
    top: 10px;
}

.provider-patient-list-detail .patient-notes textarea {
    resize: none;
    height: 125px;
}

.provider-patient-list-detail .btn-success.approved {
    background-color: #00bd1e !important;
    border: #00bd1e !important;
}
.availability.btn-success:focus{
    background-color: #00bd1e !important;
    border: #00bd1e !important;
}

.provider-patient-list-detail .btn-secondary {
    background-color: #b1b1b1 !important;
    border: #b1b1b1 !important;
}

.provider-patient-list-detail .btn:focus {
    box-shadow: none !important;
}

.provider-patient-list-detail li {
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 18px;
    margin-bottom: 3px;
}

.provider-patient-list-detail h4 {
    letter-spacing: -1px;
    color: #49495a !important;
    font-size:20px;
}

.provider-patient-list-detail .collapse figcaption {
    color: #50969a !important;
}

.provider-patient-list-detail .left-border {
    border-left: 2px solid #d6d9dc;
    height: calc(100vh - 80px) !important;
    overflow-y: auto;
    overflow-x: hidden;
}
@media(max-width:992px){
    .provider-patient-list-detail .left-border {
        border-left: none !important;
        height: auto !important;

    }

}

.provider-patient-list-detail .fa-search-plus:before {
    color: cornflowerblue;
}

.provider-patient-list-detail .fa-phone:before {
    color: #31bd1e;
}

.provider-patient-list-detail .prev-notes,
.med-details {
    line-height: 20px;
}

.provider-patient-list-detail .collapse img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.provider-avatar {
    object-fit: cover !important;
}

.provider-patient-list-detail #comm-17_chat {
    fill: #ff8918;
}

.provider-patient-list-detail #phone {
    fill: #00bd1e;
}

.provider-patient-list-detail #search_folder {
    fill: #38a9e9;
}

.provider-patient-list-detail svg.icons {
    margin-top: -14px;
}

@media (min-width: 1840.98px) {
    .provider-patient-list-detail .patient-list-dropdown {
        display: flex !important;
        justify-content: space-between !important;
    }
}


/* collaps cards */

.provider-patient-list-detail .collapse .card {
    background-color: #e1e1e1 !important;
    border: none !important;
}


/* Patient list details css ends */


/* provider patient list  css starts */

.patient-list h2 {
    font-size: 2.5rem;
}

.patient-list .fa-phone {
    color: #00BD1E !important;
}

.patient-list .fa-flag {
    color: #FF0000 !important;
}

.patient-list table {
    background-color: #ffffff !important;
}

.patient-list .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #e1e1e1 !important;
}

.patient-list .dr-name {
    line-height: 5px;
}

.patient-list .bar-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.patient-list .bar-text span {
    text-align: center;
    line-height: 1.2;
    font-size: small;
    color: #b5b6b6;
    font-weight: 500;
}

.patient-list .form-control:focus {
    color: #212529;
    background-color: none !important;
    border-color: none !important;
    box-shadow: none !important;
}

.patient-list .form-control {
    background-color: #ffffff00 !important;
    border: 1px solid #ced4da !important;
}

.patient-list .filter .fa {
    font-size: larger !important;
}

.patient-list .filter {
    letter-spacing: -1px;
}

.patient-list .arrow-up .fa {
    position: absolute;
    top: -12px;
    color: #cbd1e1;
}


.patient-list .client-dropdown .dropdown .btn-secondary {
    gap: 80px !important;
}

.patient-list .Status-dropdown .dropdown .btn-secondary {
    gap: 80px !important;
}

.patient-list .client-dropdown {
    margin-right: 10px !important;
}

.patient-list .communication-dropdown {
    margin-right: 10px !important;
}

.patient-list .patient-list-dropdown .dropdown .btn-secondary {
    color: #49495b!important;
    background-color: transparent !important;
    border: 1px solid #d0d2d3 !important;
}
 .header-dropdown-btn .btn-secondary:hover {
    box-shadow: none !important;
    background-color: #fff !important;
    color: #49495b !important;
}


.patient-list .patient-list-dropdown .dropdown .btn-secondary:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    color: #49495b !important;
}

.patient-list .fa {
    cursor: pointer;
}

.patient-list .patient-list-dropdown .dropdown-toggle:after , .provider-profile-availability .dropdown-toggle:after{
    position: absolute !important;
    right: 13px !important;
}


/* bar chart*/

.patient-list .bar-chart {
    border-radius: 20px;
    position: relative;
    border: none !important;
    box-shadow: 0 11px 13px 0 rgb(0 0 0 / 24%);
    transition: 0.3s;
    margin-top: 2rem;
    background-color: #f8f8f8 !important;
}

.patient-list .bar-chart .header-dropdown {
    position: absolute;
    top: 16px;
    right: 25px;
}

.patient-list .bar-chart .dropdown .btn-secondary {
    color: #ffcc50 !important;
    background-color: #ffdc863b !important;
    border: 1px solid #e6e6e6 !important;
    display: flex;
    gap: 3px;
    border-radius: 9px;
    color: #ffdc86;
    font-weight: bold;
    height: 28px;
    COLOR: #ffdc86;
    font-size: 13px !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 38%) !important;
    transition: 0.3s !important;
}

.patient-list .bar-chart .dropdown-toggle::after {
    color: #ffc107 !important;
    border-top: 0.6em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    border-left: 0.5em solid transparent !important;
    margin-top: 5px !important;
}
.patient-list .border-left-snapshot{
    border-left: 2px solid #d6d9dc;
    height: calc(100vh - 80px) !important;
    overflow-y: auto;
    overflow-x: hidden;
}
.patient-list .right-section {
    padding-right: 2rem;
}

@media (max-width:992px) {
    .patient-list .border-left-snapshot{
            border-left: none !important;
            padding-left: 0px !important;
            height: auto!important;
        }
        .patient-list .right-section {
            padding-right: 0rem;
        }
        
    }

/* provider patient list  css ends */



/* Patient report screen css start  */



.patient-consult-report .pdf-logo {
    position: absolute;
    top: 9px;
    left: 16px;
}


.patient-consult-report .pdf-header {
    height: 80px;
    width: 100%;
    position: relative;
    background-color: #285cb7;
}

 .patient-consult-report .main { background-color: #eef2fa; border-radius: 20px; border-top-left-radius: 0 !important; 
    border-top-right-radius: 0 !important;}

    .patient-consult-report .main-2 {
        border-radius: 20px;
        background-color: #eef2fa;
    }
 .patient-consult-report p, .patient-consult-report span { margin-left: 5px !important;}
.patient-consult-report span {color: #49495a !important;}
.patient-consult-report .radio-btn {margin-right: 7px; border: 1px solid; }
.patient-consult-report .last-visit-wd{
    min-width: 40px;
}
.patient-consult-report .pdf-medications-dropdown {
    color: #ffffff!important;
    border-radius: 6px;
    border: 1px solid #0d6efd!important;
    background: #0d6efd!important;
    margin: 0px 0px 3px 6px !important;
    display: inline-block;
}
.patient-consult-report .current-medications-dropdown{
    background-color: #ffffff;
    border-radius: 10px;
}
/* Patient report screen css End  */
/* multi selecte dropdown */
/* multi selecte dropdown end*/
.dropdown-client-list-scroller {
    max-height: 170px !important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
}
.cal-event-actions a .times-icon{
    position: absolute !important;
    right: 6px !important;
    top: 5px !important;
    font-size: 14px !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 50% !important;
    padding: 4px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 20px !important;
    height: 20px !important;
}
.cal-has-events{ background-color: #ffc029 !important; border-radius: 50px !important;color: #000 !important; }
.cal-day-badge{display: none !important;}
.cal-events{display: none !important;}



.cal-week-view * {
    font-weight: 800 !important;
}
.bs-tooltip-bottom{
    z-index: 999999 !important;
    top: -40px !important;
}

/*.................................. Patient-Pharmacy-Selection Starts...........................................*/
/*.................................. Patient-Pharmacy-Selection Starts...........................................*/
/*.................................. Patient-Pharmacy-Selection Starts...........................................*/


.pharmacy-selection{
    background-color: #C4C4C4 !important;
    border-radius: 20px !important;
}
.pharmacy-selection-text-indent{
    text-indent: 20px!important;
}
.select-pharmacies-label{
        font-weight: 700;
        color: #49495b;
        font-size: 18px;
}

.pharmacy-list {
    height: 350px;
    overflow-x: hidden;
}

.pharmacy-list ul {
    list-style-type: none;
    word-break: break-all;
}

.pharmacy-list input {
    position: absolute;
    top: 6px;
    left: 22px;
}

/*.................................. Patient-Pharmacy-Selection Ends...........................................*/
/*.................................. Patient-Pharmacy-Selection Ends...........................................*/
/*.................................. Patient-Pharmacy-Selection Ends...........................................*/

.reports-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    
}
.reports-loading .spinner-border{
    color: #285cb7;
    width: 5rem;
    height:5rem ;
}

.not-found {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
    .img-404 {
      background: url("./assets/images/404.svg");
      display: block;
      width: 500px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
    }
 .pr-38{
    padding-right: 38px !important;
 }

 .affilate-table .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #cecece !important;
  }
  .ng-select.ng-select-single .ng-select-container {
    z-index: 1 !important;
  }
  .affiliate-dropdown .ng-select.ng-select-single .ng-select-container {
    height: 38px !important;
    border-radius: 10px !important;
  }
.report-icons{
    border: 1px solid;
    border-radius: 6px;
}
.multiselect-dropdown .disabled > span {
    background-color: #eceeef !important;
    pointer-events: none;
}
.signature-pad-canvas{
    border: none !important;
}
.readonly{
    pointer-events: none;
    opacity : 0.5;
}

html.has-visible-scrollbar.pretty-scrollbars::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
}
@media (max-width: 1200px){
    .expand-sections-patient-list-scroller {
        max-height: calc(100vh - 506px) !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
}
textarea{
    resize: none !important;
}
.admin-setting-patient-history-list-scroller{
    max-height: calc(100vh - 735px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}